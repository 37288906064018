// src/components/App.js

import React, { useState, useEffect } from 'react';
import { MouseProvider } from './contexts/MouseContext';
import ScrollContainer from './ScrollContainer';
import './App.css';
import { ParallaxProvider } from 'react-scroll-parallax';
import Navbar from './Navbar'; // Ensure correct path

function App() {
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    // Debounce resize handler to prevent excessive state updates
    let resizeTimer;
    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setResizeKey((prevKey) => prevKey + 1);
      }, 300); // Adjust delay as needed
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(resizeTimer);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ParallaxProvider>
      <MouseProvider>
        <div className="App">
          <Navbar /> {/* Include Navbar */}
          <ScrollContainer key={resizeKey} /> {/* Pass resizeKey as key to force remount */}
        </div>
      </MouseProvider>
    </ParallaxProvider>
  );
}

export default App;
