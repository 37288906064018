// src/components/Navbar.js

import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };

  const closeMobileMenu = () => {
    setIsMobile(false);
  };

  // Animation variants for mobile menu
  const menuVariants = {
    hidden: {
      opacity: 0,
      x: '-100%',
      transition: { duration: 0.5 }
    },
    visible: {
      opacity: 1,
      x: '0%',
      transition: { duration: 0.5 }
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo can be re-added here if desired */}
        {/* <div className="logo">MyLogo</div> */}

        {/* Hamburger Icon */}
        <div
          className="menu-icon"
          onClick={toggleMobileMenu}
          aria-label="Toggle Navigation Menu"
          tabIndex={0} // Make it focusable for accessibility
          onKeyPress={(e) => {
            if (e.key === 'Enter') toggleMobileMenu();
          }}
        >
          {isMobile ? <FaTimes /> : <FaBars />}
        </div>

        {/* Navigation Links */}
        <motion.ul
          className={isMobile ? 'nav-menu active' : 'nav-menu'}
          variants={isMobile ? menuVariants.visible : menuVariants.hidden}
          initial="hidden"
          animate={isMobile ? 'visible' : 'hidden'}
        >
          <li className="nav-item">
            <Link
              activeClass="active"
              to="landing"
              spy={true}
              smooth={true}
              offset={-60} // Adjust based on Navbar height
              duration={500}
              onClick={closeMobileMenu}
              className="nav-links"
              containerId="scroll-container" // Specify the scroll container
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="portfolio"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
              onClick={closeMobileMenu}
              className="nav-links"
              containerId="scroll-container"
            >
              Portfolio
            </Link>
          </li>
          {/* <li className="nav-item">
            <a
              href="/resume.pdf"
              download
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Resume
            </a>
          </li> */}
          <li className="nav-item">
            <a
              href="https://www.instagram.com/treyschibli/"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Instagram
            </a>
          </li>
          <li className="nav-item">
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}
              onClick={closeMobileMenu}
              className="nav-links"
              containerId="scroll-container"
            >
              Contact
            </Link>
          </li>
        </motion.ul>
      </div>
    </nav>
  );
}

export default Navbar;
