// src/components/ScrollContainer.js

import React, { useState, useRef, useEffect, Suspense } from 'react';
import { useInView } from 'react-intersection-observer';
import { Element } from 'react-scroll';
import ProjectTransition from './ProjectTransition';
import projects from '../data/projects';
import LandingSection from './LandingSection';
import './ScrollContainer.css';
import Contact from './Contact';
import ScrollIndicator from './ScrollIndicator';

// Lazy load the Portfolio component
const Portfolio = React.lazy(() => import('./Portfolio'));

function ScrollContainer() {
  const scrollContainerRef = useRef(null);
  
  // State to determine if Portfolio should load immediately
  const [shouldLoadPortfolio, setShouldLoadPortfolio] = useState(false);

  // State to determine if the user is on a mobile device
  const [isMobile, setIsMobile] = useState(false);

  // Check if URL has a hash on mount
  useEffect(() => {
    if (window.location.hash) {
      setShouldLoadPortfolio(true);
    }

    // Check if the user is on a mobile device
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  // useInView hook to detect when Portfolio section is in view
  const { ref: portfolioRef, inView: portfolioInView } = useInView({
    threshold: 0.1,
    root: scrollContainerRef.current || null,
    triggerOnce: true,
  });

  return (
    <div className="scroll-container" ref={scrollContainerRef} id="scroll-container">
      {/* Projects Section */}
      <div className="projects-section">
        {/* {!isMobile && <LandingSection />} */}
        <div className="project-grid">
          {projects.map((project) => (
            <ProjectTransition
              key={project.id}
              project={project}
              rootRef={scrollContainerRef} // Pass rootRef here
            />
          ))}
        </div>
      </div>

      {/* Portfolio Section */}
      <Element name="portfolio" className="portfolio-section">
        <div ref={portfolioRef}>
          {(shouldLoadPortfolio || portfolioInView) ? (
            <Suspense fallback={<div>Loading Portfolio...</div>}>
              <Portfolio />
            </Suspense>
          ) : (
            // Placeholder to maintain layout
            <div style={{ minHeight: '100vh' }}></div>
          )}
        </div>
      </Element>

      {/* Contact Section */}
      <Contact />

      {/* Scroll Indicator */}
      {/* <ScrollIndicator scrollContainerRef={scrollContainerRef} /> */}
    </div>
  );
}

export default ScrollContainer;