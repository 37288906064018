// src/contexts/MouseContext.js
import React, { createContext, useState, useEffect } from 'react';
import { debounce } from 'lodash';

export const MouseContext = createContext({
  mouseX: 0,
  mouseY: 0,
});

export const MouseProvider = ({ children }) => {
  const [mouse, setMouse] = useState({ mouseX: 0, mouseY: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      // Normalize mouse coordinates between -1 and 1
      const x = (event.clientX / window.innerWidth) * 2 - 1;
      const y = -(event.clientY / window.innerHeight) * 2 + 1;
      setMouse({ mouseX: x, mouseY: y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <MouseContext.Provider value={mouse}>
      {children}
    </MouseContext.Provider>
  );
};
