import React, { useState } from 'react';
import ProjectPage from './ProjectPage';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { useScroll } from '@use-gesture/react';
import { throttle } from 'lodash';
import ScrollIndicator from './ScrollIndicator'; // Import ScrollIndicator
import './ProjectTransition.css';

function ProjectTransition({ project, rootRef }) {
  // Manage isExpanded state internally
  const [isExpanded, setIsExpanded] = useState(false);

  // Function to toggle expansion
  const handleToggle = () => {
    setIsExpanded(prev => !prev);
  };

  const [ref, inView] = useInView({
    threshold: 0.45,
    root: rootRef.current || null,
    triggerOnce: false,
  });

  const [{ scrollY }, api] = useSpring(() => ({
    scrollY: 0,
    config: { mass: 1, tension: 120, friction: 14 },
  }));

  const throttledScroll = throttle(({ xy: [, y] }) => {
    api.start({ scrollY: y });
  }, 50); // Adjust the throttle delay as needed

  useScroll(throttledScroll, {
    target: rootRef.current,
    eventOptions: { passive: true },
  });

  return (
    <animated.div
      ref={ref}
      className={`shader-project ${isExpanded ? 'expanded' : ''}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '100%',
      }}
    >
      <ProjectPage
        project={project}
        isVisible={inView || isExpanded}
        isExpanded={isExpanded}
        onToggle={handleToggle} // Pass the toggle function
        scrollY={scrollY}
      />
      {/* Scroll Indicator to scroll to next section */}
      <ScrollIndicator targetSection="portfolio" />
    </animated.div>
  );
}

export default ProjectTransition;