// src/components/ShaderPlane.js

import React, { useRef, useEffect, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { debounce } from 'lodash';


// Import shaders
import vertexShaderSimple from '../shaders/vertexShaderSimple.glsl';
import fragmentShaderRipple from '../shaders/fragmentShaderRipple.glsl';
import vertexShaderWave from '../shaders/vertexShaderWave.glsl';
import fragmentShaderWave from '../shaders/fragmentShaderWave.glsl';
import vertexShaderGrid from '../shaders/vertexShaderGrid.glsl';
import fragmentShaderGrid from '../shaders/fragmentShaderGrid.glsl';
import vertexShaderFirefly from '../shaders/vertexShaderFirefly.glsl';
import fragmentShaderFirefly from '../shaders/fragmentShaderFirefly.glsl';

// Import the 'foundations' shaders
import vertexShaderFoundations from '../shaders/vertexShaderFoundations.glsl';
import fragmentShaderFoundations from '../shaders/fragmentShaderFoundations.glsl';

// Import the 'oscilloscope' shaders
import vertexOscilloscope from '../shaders/vertexShaderOscilloscope.glsl';
import fragmentOscilloscope from '../shaders/fragmentShaderOscilloscope.glsl';

// Import the 'interactive' shaders
import vertexShaderInteractive from '../shaders/vertexShaderInteractive.glsl';
import fragmentShaderInteractive from '../shaders/fragmentShaderInteractive.glsl';

import vertexShaderInfiniteZoom from '../shaders/vertexShaderInfiniteZoom.glsl';
import fragmentShaderInfiniteZoom from '../shaders/fragmentShaderInfiniteZoom.glsl';

import vertexStunningMandala from '../shaders/vertexStunningMandala.glsl';
import fragmentStunningMandala from '../shaders/fragmentstunningMandala.glsl';

import vertexCurrently from '../shaders/vertexCurrently.glsl';
import fragmentCurrently from '../shaders/fragmentCurrently.glsl';

// Define shader mappings
const shaders = {
  ripple: {
    vertex: vertexShaderSimple,
    fragment: fragmentShaderRipple,
  },
  wave: {
    vertex: vertexShaderWave,
    fragment: fragmentShaderWave,
  },
  grid: {
    vertex: vertexShaderGrid,
    fragment: fragmentShaderGrid,
  },
  firefly: {
    vertex: vertexShaderFirefly,
    fragment: fragmentShaderFirefly,
  },
  foundations: {
    vertex: vertexShaderFoundations,
    fragment: fragmentShaderFoundations,
  },
  beatsToBytes: {
    vertex: vertexOscilloscope,
    fragment: fragmentOscilloscope,
  },
  interactive: {
    vertex: vertexShaderInteractive,
    fragment: fragmentShaderInteractive,
  },
    masteringShaders: {
    vertex: vertexShaderInfiniteZoom,
    fragment: fragmentShaderInfiniteZoom,
  },
    mandalaShaders: {
    vertex: vertexStunningMandala,
    fragment: fragmentStunningMandala,
  },
    currentlyShaders: {
    vertex: vertexCurrently,
    fragment: fragmentCurrently,
  },
  // ... add other shaders as needed
};

function ShaderPlane({ shaderName, opacity = 1.0 }) {
  const materialRef = useRef();
  const mouseRef = useRef(new THREE.Vector2(0, 0));
  const clickPositionRef = useRef(new THREE.Vector2(-10, -10));
  const clickTimeRef = useRef(-10);
  const [resolution, setResolution] = useState(
    new THREE.Vector2(window.innerWidth, window.innerHeight)
  );

  const handleMouseMove = (event) => {
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;
    mouseRef.current.set(x, y);
  };

  const handleMouseClick = (event) => {
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;
    clickPositionRef.current.set(x, y);
    clickTimeRef.current = performance.now() / 1000;
  };

  const debouncedHandleResize = debounce(() => {
    const newResolution = new THREE.Vector2(window.innerWidth, window.innerHeight);
    setResolution(newResolution);
    if (materialRef.current && materialRef.current.uniforms.uResolution) {
      materialRef.current.uniforms.uResolution.value.copy(newResolution);
    }
  }, 200);

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('click', handleMouseClick);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('resize', debouncedHandleResize);
      window.removeEventListener('click', handleMouseClick);
      debouncedHandleResize.cancel();
    };
  }, [debouncedHandleResize]);

  useEffect(() => {
    return () => {
      if (materialRef.current) {
        materialRef.current.dispose();
      }
    };
  }, []);

  // ShaderPlane.js
    useEffect(() => {
        const canvas = materialRef.current?.gl?.domElement;
        if (!canvas) return;
    
        const handleContextLost = (event) => {
        event.preventDefault();
        console.warn('WebGL context lost');
        // Optionally, attempt to restore or notify the user
        };
    
        const handleContextRestored = () => {
        console.info('WebGL context restored');
        // Reinitialize shaders or other necessary components
        };
    
        canvas.addEventListener('webglcontextlost', handleContextLost, false);
        canvas.addEventListener('webglcontextrestored', handleContextRestored, false);
    
        return () => {
        canvas.removeEventListener('webglcontextlost', handleContextLost);
        canvas.removeEventListener('webglcontextrestored', handleContextRestored);
        };
    }, []);
    

  const shader = shaders[shaderName];

  useFrame(({ camera, clock }) => {
    if (materialRef.current && shader) {
      const elapsedTime = clock.getElapsedTime();
      if (materialRef.current.uniforms.uTime) {
        materialRef.current.uniforms.uTime.value = elapsedTime;
      }

      if (materialRef.current.uniforms.uMouse) {
        materialRef.current.uniforms.uMouse.value.copy(mouseRef.current);
      }

      if (materialRef.current.uniforms.uClickPosition) {
        materialRef.current.uniforms.uClickPosition.value.copy(clickPositionRef.current);
      }

      if (materialRef.current.uniforms.uClickTime) {
        materialRef.current.uniforms.uClickTime.value = clickTimeRef.current;
      }

      if (materialRef.current.uniforms.uResolution) {
        materialRef.current.uniforms.uResolution.value.copy(resolution);
        //console.log('uResolution updated:', resolution);
      }

      if (materialRef.current.uniforms.uOpacity) {
        materialRef.current.uniforms.uOpacity.value = opacity;
      }

      if (materialRef.current.uniforms.uCameraPosition) {
        materialRef.current.uniforms.uCameraPosition.value.copy(camera.position);
      }
    }
  });

  if (!shader) {
    console.error(`Shader "${shaderName}" not found! Please check the shaderName prop.`);
    return null;
  }

  const uniforms = {
    uTime: { value: 0 },
    uMouse: { value: mouseRef.current },
    uClickPosition: { value: clickPositionRef.current },
    uClickTime: { value: clickTimeRef.current },
    uResolution: { value: resolution },
    uOpacity: { value: opacity },
    uCameraPosition: { value: new THREE.Vector3() },
  };

  if (shaderName === 'foundations') {
    return (
      <mesh>
        <planeGeometry args={[20, 20, 400, 400]} />
        <shaderMaterial
          ref={materialRef}
          vertexShader={shader.vertex}
          fragmentShader={shader.fragment}
          uniforms={uniforms}
          transparent={true}
          wireframe={false}
        />
      </mesh>
    );
  } else if (shaderName === 'masteringShaders') {
    return (
      <mesh>
        <planeGeometry args={[20, 10, 400, 400]} />
        <shaderMaterial
          ref={materialRef}
          vertexShader={shader.vertex}
          fragmentShader={shader.fragment}
          uniforms={uniforms}
          transparent={true}
          wireframe={false}
        />
      </mesh>
    );
  } else {
    return (
      <mesh>
        <planeGeometry args={[20, 10, 100, 100]} />
        <shaderMaterial
          ref={materialRef}
          vertexShader={shader.vertex}
          fragmentShader={shader.fragment}
          uniforms={uniforms}
          transparent={true}
          wireframe={false}
        />
      </mesh>
    );
  }
}

export default ShaderPlane;