// src/components/ScrollIndicator.js

import React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Link, scroller } from 'react-scroll';
import './ScrollIndicator.css';

function ScrollIndicator({ targetSection }) {
  const handleClick = () => {
    scroller.scrollTo(targetSection, {
      smooth: true,
      duration: 500,
      offset: -60,
    });
  };

  return (
    <div className="scroll-indicator" onClick={handleClick}>
      <motion.div
        className="icon-container"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          delay: 1, // Delay the appearance after landing
          duration: 0.5,
        }}
        whileHover={{ scale: 1.2 }}
      >
        <FaChevronDown size={30} color="#ffffff" />
      </motion.div>
      <span className="tooltip">Scroll Down</span>
    </div>
  );
}

export default ScrollIndicator;