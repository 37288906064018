// src/components/Contact.js

import React from 'react';
import './Contact.css';
import { Element } from 'react-scroll';

function Contact() {
  return (
    <Element name="contact" className="contact-section">
      <div className="contact-content">
        <h2>Contact</h2>
        <p>email: me@treyschibli.com.</p>
        {/* Add your contact form or details here */}
      </div>
    </Element>
  );
}

export default Contact;
