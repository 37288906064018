// src/components/ShaderBackground.js

import React, { useRef, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import ShaderPlane from './ShaderPlane';
import './ShaderBackground.css';
import { motion } from 'framer-motion';

const ShaderBackground = React.memo(({ shaderName, scrollY }) => {
  const canvasRef = useRef();

  useEffect(() => {
    const handleContextLost = (event) => {
      event.preventDefault();
      console.warn('WebGL context lost.');
      // Optionally, display a message to the user or attempt to restore.
    };

    const handleContextRestored = () => {
      console.info('WebGL context restored.');
      // Reinitialize shaders or other necessary components.
    };

    const canvas = canvasRef.current;

    if (canvas) {
      canvas.addEventListener('webglcontextlost', handleContextLost, false);
      canvas.addEventListener('webglcontextrestored', handleContextRestored, false);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('webglcontextlost', handleContextLost);
        canvas.removeEventListener('webglcontextrestored', handleContextRestored);
      }
    };
  }, []);

  return (
    <motion.div
      className="shader-background"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.2, ease: 'easeInOut' }}
      style={{
        transform: scrollY.to((y) => `translateY(${y * -0.2}px)`),
      }}
    >
      <Canvas
        ref={canvasRef}
        camera={{ position: [0, 0, 5], fov: 75 }}
        style={{ width: '100%', height: '100%' }}
        gl={{ alpha: true, antialias: true }}
      >
        <ShaderPlane shaderName={shaderName} />
      </Canvas>
    </motion.div>
  );
});

export default ShaderBackground;
