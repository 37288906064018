// src/components/ProjectPage.js

import React, { useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactDOM from 'react-dom';
import ShaderBackground from './ShaderBackground';
import './ProjectPage.css';

const portalRoot = document.getElementById('portal-root');

if (!portalRoot) {
  console.error('Portal root with id "portal-root" not found in the DOM.');
}

const ProjectPage = React.memo(
  ({ project, isVisible, isExpanded, onToggle, scrollY }) => {
    const contentRef = useRef(null);

    useEffect(() => {
      if (isExpanded) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
      return () => {
        document.body.classList.remove('no-scroll');
      };
    }, [isExpanded]);

    // Handle click outside to close
    const handleOverlayClick = (e) => {
      if (e.target === e.currentTarget) {
        onToggle();
      }
    };

    const content = (
      <motion.div
        className={`project-page ${isExpanded ? 'expanded' : ''}`}
        layout
        initial={false}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={{
          expanded: { /* expanded animation styles */ },
          collapsed: { /* collapsed animation styles */ },
        }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{
          position: isExpanded ? 'fixed' : 'relative',
          top: isExpanded ? 0 : 'auto',
          left: isExpanded ? 0 : 'auto',
          width: isExpanded ? '100vw' : '100%',
          height: isExpanded ? '100vh' : 'auto',
          zIndex: isExpanded ? 1000 : 'auto',
        }}
        onClick={isExpanded ? handleOverlayClick : undefined} // Add click handler when expanded
      >
        {isVisible && (
          <ShaderBackground
            shaderName={project.shader}
            scrollY={scrollY}
          />
        )}
        <motion.div
          className="content-wrapper"
          onClick={onToggle}
          initial={isExpanded ? { y: '100%' } : {}}
          animate={isExpanded ? { y: 0 } : {}}
          exit={{ y: '100%' }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          ref={contentRef}
        >
          <AnimatePresence mode="wait">
            {!isExpanded && (
              <motion.div
                key="collapsed-content"
                className="project-content"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              >
                <h1>{project.title}</h1>
                <p>{project.description}</p>
              </motion.div>
            )}
            {isExpanded && (
              <motion.div
                key="expanded-content"
                className="project-expanded-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                onClick={(e) => e.stopPropagation()} // Prevent click from bubbling to overlay
              >
                {/* Close Button */}
                <button
                  className="close-button"
                  onClick={onToggle}
                  aria-label="Close Project"
                >
                  &times;
                </button>
                <h1>{project.title}</h1>
                <p>{project.longDescription}</p>
                {project.highlights && (
                  <ul className="highlights-list">
                    {project.highlights.map((highlight, index) => (
                      <li key={index}>{highlight}</li>
                    ))}
                  </ul>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    );

    return isExpanded ? ReactDOM.createPortal(content, portalRoot) : content;
  }
);

export default ProjectPage;